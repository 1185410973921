@font-face {
    font-family: "Poppins";
    src: local('/static/fonts/poppins/poppins-v20-latin-300.woff'), url('/static/fonts/poppins/poppins-v20-latin-300.woff') format('woff');
    src: local('/static/fonts/poppins/poppins-v20-latin-300.woff2'), url('/static/fonts/poppins/poppins-v20-latin-300.woff2') format('woff2');
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "Poppins";
    src: local('/static/fonts/poppins/poppins-v20-latin-200.woff'), url('/static/fonts/poppins/poppins-v20-latin-200.woff') format('woff');
    src: local('/static/fonts/poppins/poppins-v20-latin-200.woff2'), url('/static/fonts/poppins/poppins-v20-latin-200.woff2') format('woff2');
    font-style: normal;
    font-weight: 200;
}
@font-face {
    font-family: "Poppins";
    src: local('/static/fonts/poppins/poppins-v20-latin-regular.woff'), url('/static/fonts/poppins/poppins-v20-latin-regular.woff') format('woff');
    src: local('/static/fonts/poppins/poppins-v20-latin-regular.woff2'), url('/static/fonts/poppins/poppins-v20-latin-regular.woff2') format('woff2');
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "Poppins";
    src: local('/static/fonts/poppins/poppins-v20-latin-600.woff'), url('/static/fonts/poppins/poppins-v20-latin-600.woff') format('woff');
    src: local('/static/fonts/poppins/poppins-v20-latin-600.woff2'), url('/static/fonts/poppins/poppins-v20-latin-600.woff2') format('woff2');
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: "Poppins";
    src: local('/static/fonts/poppins/poppins-v20-latin-700.woff'), url('/static/fonts/poppins/poppins-v20-latin-700.woff') format('woff');
    src: local('/static/fonts/poppins/poppins-v20-latin-700.woff2'), url('/static/fonts/poppins/poppins-v20-latin-700.woff2') format('woff2');
    font-style: normal;
    font-weight: 700;
}
