body,
html {
  font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  color: #333;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

* {
  font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
  box-sizing: border-box;
  font-weight: 300;
}

button,
a {
  cursor: pointer;
}

.custom-plan-price-tooltip {
  width: 200px;
  text-align: center;
}

.no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}